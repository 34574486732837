import { derived, writable } from 'svelte/store';

const createCollectionSideDrawer = () => {
	const isExpanded = writable(false);

	const data = derived(isExpanded, ($isExpanded) => {
		return {
			isExpanded: $isExpanded
		};
	});

	return {
		...data,
		toggle: () => {
			isExpanded.update((value) => !value);
		},
		setExpanded: (value: boolean) => {
			isExpanded.set(value);
		}
	};
};

export const collectionSideDrawer = createCollectionSideDrawer();
